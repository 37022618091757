import React from "react"
import { firebase_auth, firebase_instance } from "../../utils/database";

import { Button } from "antd";
import { useNavigate } from "react-router";

const AuthSocialForm = () => {
    const history = useNavigate();
    
    const onSocialClick = async (event) => {
        const { currentTarget: {name} } = event;

        let provider;
        if (name === "google") {
            provider = new firebase_instance.auth.GoogleAuthProvider();
        }

        await firebase_auth.signInWithPopup(provider);
        history("/")
    }

    return(
        <div className="mb-2">
            <Button type="primary" danger onClick={onSocialClick} name="google">Log in with Google</Button>
        </div>
    )
}

export default AuthSocialForm;