import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { firestore } from "../../utils/database";

import { Button, Modal, message } from 'antd';
import { MailOutlined, CloseOutlined} from '@ant-design/icons';

const MessageList = ({isPeopleTab}) => {
    const auth = useSelector((state) => state.auth);

    const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);
    const [receivedMessages, setReceivedMessages] = useState(null);

    useEffect(() => {
        if (receivedMessages === null && auth.currentUser)
            getMessages();
    });

    const getMessages = async () => {
        const messagesRef = await firestore.collection("message")
        .where(
            "receiver",
            "==",
            auth.currentUser.uid
        )
        .orderBy("date")
        .get();

        const messages = messagesRef.docs.map(doc => {
            return {
                ...doc.data(),
                id: doc.id,
            }
        });

        setReceivedMessages(messages);
    }

    const removeMessage = async (e) => {
        const messageId = e.currentTarget.id.substring(1,);
        e.currentTarget.parentElement.remove();

        const messageRef = await firestore.collection("message").doc(messageId);
        await messageRef.delete();

        e.target.parentElement.parentElement.remove();

        message.success('Successfully deleted');
    }

    const viewProfile = (senderID) => {
        window.location.hash="#"+senderID;
        setIsMessageModalVisible(false);
        return true;
    }
    
    return (
        <>
        <Button id="message-btn" type="primary" shape="round" icon={<MailOutlined />} size="large" onClick={() => setIsMessageModalVisible(true)}>
            Messages ({receivedMessages ? receivedMessages.length : 0})
        </Button>
        <Modal title="Messages" 
            visible={isMessageModalVisible} 
            onOk={() => setIsMessageModalVisible(false)} 
            onCancel={() => setIsMessageModalVisible(false)}
            footer={""}
        >

            {receivedMessages && receivedMessages.slice(0).reverse().map((m, i) => 
                <div className="message" key={i}>
                    <b>{m.senderName}</b> wants to meet you and left a message at {m.date.toDate().toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}: <br/>
                    <span className="text-muted">{m.message}</span><br/>
                    {isPeopleTab && <a href={`#${m.sender}`} className="text-primary" onClick={(e) => viewProfile(m.sender)}>See {m.senderName}'s profile</a>}
                    <span id={`m${m.id}`} className="close" onClick={(e) => removeMessage(e)}><CloseOutlined /></span>
                </div>
            )}
        </Modal>
        </>
    )
};

export default MessageList;