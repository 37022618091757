import posTagger from 'wink-pos-tagger';
import { Corpus, Similarity } from "tiny-tfidf";

import proceeding from "../components/paperSource";

import { firebase_instance, firestore } from "../utils/database";

const customStopwords = ["behind", "improves", "fully", "findings", "--", "ten", "month", "user"];

const conf_ID = 10127; // conference ID from SIGCHI app

const isMyColleague = (me, other) => {
    // see if they write papers together this year
    if(me["author"] && other["author"]) {
        const my_papers = proceeding["papers"].filter(p => p.authors.includes(me["author"]));
        if( my_papers.filter(p => p.authors.includes(other["author"])).length )
            return true;
    }

    // see if they write previous papers together
    if(me["selectedMatch"].length && other["selectedMatch"] && other["selectedMatch"].length) {
        const other_ids = other["selectedMatch"].map(s => s.id)
        for (const p in me.prev_papers) {
            if( me.prev_papers[p]["authors"].filter(a => other_ids.includes( a["authorId"])).length  )
                return true;
        }
    }

    return false;
}

const getColleaguesSS = async (prevCollleagues) => {
    // return SS id of co-authors of the colleagues
    let colleauges = [];

    let query_url= `https://api.semanticscholar.org/graph/v1/author/batch?fields=papers.authors,papers.year`;

    for (var i = 0; i <= prevCollleagues.length/100; i++) {
        const c = prevCollleagues.slice(i*100, (i+1)*100)
        // console.log(c)

        let res = await fetch(query_url, {
            method: "POST",
            headers: {
                // 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_MAG_SUB_KEY,
                'Content-Type': 'application/json',
                // 'x-api-key': process.env.REACT_APP_SS_API_KEY,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({"ids": c})
        });

        const paper_list = await res.json();
        // process the recent papers
        const d = new Date();
        paper_list
            .forEach(function(e) {
            if(!e) return;
            const papers = e.papers;
            papers.forEach(function(element) {
                if( element.authors.length < 9 && d.getFullYear() - element.year < 15)
                    colleauges.push( ...element.authors );
            }, this);
        }, this);
    
        

        

     }

     colleauges = colleauges.filter(c => !prevCollleagues.includes(c['authorId']));
     const cIds = [...new Set(colleauges.map(c => c['authorId']))];
     const cp = colleauges;
     colleauges = [];
     cIds.forEach(function(element) {
         const m = cp.filter(c => c['authorId'] == element);
         if(m.length)
         colleauges.push( m[0] )
     }, this);
    
    return colleauges;
}

const getBaconDistance = async (me, mColleauges, other) => {
    if(!(me["selectedMatch"].length && other["selectedMatch"] && other["selectedMatch"].length)) {
        return [5, []];
    }

    const mids = me["selectedMatch"].map(s => s.id);
    const oids = other["selectedMatch"].map(s => s.id);
    
    const c1 = mColleauges['coauthor1'].filter(m => oids.includes(m['authorId']));
    if(c1.length)
        return [1, []];
        

    const ocRef = await firestore.collection("network").doc(other.id).get();

    const oColleagues = await ocRef.data();

    const o1IDs = oColleagues ? oColleagues['coauthor1'].map(o => o['authorId']) : [];

    if(mColleauges['coauthor2'].filter(m => oids.includes(m['authorId'])).length) {
        const c2 = mColleauges['coauthor1'].filter(m => o1IDs.includes(m['authorId'])) 
        return [2, c2];
    } 

    const c3 = mColleauges['coauthor2'].filter(m => o1IDs.includes(m['authorId']));
    if(c3.length) 
        return [3, c3];
    
    const c4 = mColleauges['coauthor2'].filter(m => o1IDs.includes(m['authorId']));
    if(c4.length) 
        return [4, c4];

    return [5, []];
}

const preprocessCorpus = (s) => {
    const tagger = posTagger()
    return tagger.tagSentence( s ).map(w => w.lemma ? w.lemma : w.value).join(" ");
}

// load what papers that I like from CHI 2022
const getLikedContent = async (soya_token) => {
    let author_url = `https://api.sigchi.org/api/cloud/external/read/all`;
            
    return fetch(author_url, {
        method: 'POST',
        body: JSON.stringify({ lastSyncDate: 0 }),
        headers: {
            "Sigchi-User-Token": soya_token,
            "Public-App-Signature": "client: Name, app: 1.0",
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then(res => res.json())
    .then(result => {
        // const entities = formatSearchResult(result).flat();
        // handleSearchMatchChange(entities);
        return result;
    }).catch(error => {
        console.error('Error occurred:', error);
        return []; // Return empty array on error
    });
};

const getKeywords = (user) => {
    const liked_content = proceeding['papers'].filter(p => user.myLikeIds.includes(p["id"]))
    
    const soya_corpus = preprocessCorpus( liked_content.map(p => p.abstract).join(" ") );

    const corpus = new Corpus(
        ["document1"],
        [
            soya_corpus
        ], 
        true,  // use default stopwords
        customStopwords
      );
      
      // print top terms for document 3
    //   console.log(corpus.getTopTermsForDocument("document1", maxTerms = 10));
      
      // result
    //   [
    //     [ 'bit', 1.9939850399669656 ],
    //     [ 'three', 1.3113595307890855 ],
    //     [ 'different', 1.3113595307890855 ],
    //     [ 'tiny', 1.3113595307890855 ],
    //     [ 'longer', 1.3113595307890855 ],
    //     [ 'number', 0.6556797653945428 ],
    //     [ 'also', 0.6556797653945428 ],
    //     [ 'test', 0.2721316901570901 ],
    //     [ 'document', 0.2721316901570901 ]
    //   ]

      return {...user, keywords: corpus.getTopTermsForDocument("document1", 10).map(k => k[0])};
}

const getExpertise = (user) => {
    const userDocument = user.prev_papers && Object.keys(user.prev_papers).length ?
    Object.keys(user['prev_papers']).map(p => user['prev_papers'][p]['abstract'] || user['prev_papers'][p]['title']).join(" ||| ")
    : "";

    return userDocument;
}

const getInterest = (user) => {
    const liked_content = proceeding['papers'].filter(p => user.myLikeIds.includes(p["id"] + ""))
    
    const userDocument = liked_content.map(p => p.abstract).join(" ||| ");

    return userDocument;
}

const compareDocuments = (me, my_document, others, others_documents) => {
    const soya_corpus = preprocessCorpus( my_document );
    const other_corpus = others_documents.map(o => preprocessCorpus( o ));

    const corpus = new Corpus(
        [me.id].concat(others.map(a => a.id)),
        [soya_corpus].concat(other_corpus),
        true,  // use default stopwords
        customStopwords
        );
          
    const sim = new Similarity(corpus);
    const sim_mat = sim.getDistanceMatrix()['matrix'];

    const sort_index = Array.from(Array(sim_mat[0].length).keys())
        .sort((a, b) => sim_mat[0][a] < sim_mat[0][b] ? -1 : (sim_mat[0][b] < sim_mat[0][a]) | 0);  
    
    // console.log(sort_index.filter(a => a !== 0).map(a =>  others_to_compare[a -1]));    
    // console.log(others_to_compare.map(p => {
    //     return p["name"] + corpus.getCommonTerms(me.id, p['id'], 5).map(d => d[0]).join("  #")
    // }));

    const others_profile = others.map(o => {
        return {commonTerms: corpus.getCommonTerms(me.id, o['id'], 10).map(d => d[0]), ...o}
    })
    


    // sorted by similiarity
    return sort_index.filter(a => a !== 0).map(a =>  others_profile[a -1]);
}

const getSimiliarUsers = (me, others) => {
    const liked_content = proceeding['papers'].filter(p => me.myLikeIds.includes(p["id"] + ""));

    console.log(proceeding['papers']);
    
    const soya_corpus = preprocessCorpus( liked_content.map(p => p.abstract).join(" ") );

    const other_joined_text = (a) => {
        // TODO when (a["author"] + "") != "-1" then append the paper
        return a.prev_papers && Object.keys(a.prev_papers).length ?
        Object.keys(a['prev_papers']).map(p => a['prev_papers'][p]['abstract'] || a['prev_papers'][p]['title']).join() // if prev_papers has no abstract, put titles
        : a.myLikeIds.map(pid => proceeding["papers"].filter(p => (p["id"] == pid) && p['abstract']).map(p => p["abstract"]).join()).join().replaceAll(",", "").trim();
    }
    const others_to_compare = others.filter(o => (other_joined_text(o).length > 0 ) // make sure there is a string for abstracts
    );

    // try to use other's publication, if they dont have pub, use their liked data
    const other_corpus = others_to_compare.map(a => preprocessCorpus( other_joined_text(a)
        ));

    const corpus = new Corpus(
        [me.id].concat(others_to_compare.map(a => a.id)),
        [soya_corpus].concat(other_corpus),
        true,  // use default stopwords
        customStopwords
        );
          
    const sim = new Similarity(corpus);
    const sim_mat = sim.getDistanceMatrix()['matrix'];

    const sort_index = Array.from(Array(sim_mat[0].length).keys())
        .sort((a, b) => sim_mat[0][a] < sim_mat[0][b] ? -1 : (sim_mat[0][b] < sim_mat[0][a]) | 0);  
    
    // console.log(sort_index.filter(a => a !== 0).map(a =>  others_to_compare[a -1]));    
    // console.log(others_to_compare.map(p => {
    //     return p["name"] + corpus.getCommonTerms(me.id, p['id'], 5).map(d => d[0]).join("  #")
    // }));

    const others_profile = others_to_compare.map(o => {
        return {commonTerms: corpus.getCommonTerms(me.id, o['id'], 10).map(d => d[0]), ...o}
    })
    


    // sorted by similiarity
    return sort_index.filter(a => a !== 0).map(a =>  others_profile[a -1])

    // if(me.liked_content.length && other.liked_content.length && me.liked_content.filter(value => other.liked_content.includes(value)).length >= 2)
    //     return true;

    // return false;
}

// determine if I can join this room 
const isJoinable = async (roomData, meID) => {
    // room id given. need to load room data
    if(!roomData.id) {
        const roomRef = await firestore.collection("room").doc(roomData);
        const roomInstance = await roomRef.get();

        roomData = roomInstance.data();
    }

    // console.log(roomData);
    // console.log(roomData.participants.length >= 1);

    if (roomData.participants.length >= 5) {
        return false; 
    }

    const meRef = await firestore.collection("settings").doc(meID);
    const meData = await meRef.get();
    const me = {
        ...meData.data(), 
        id: meID,
    };

    // if senior, even if there is less than 5 people, they are some conditions to join the room
    if(me.stage == "faculty") {
        console.log(roomData.participants);
        const othersRef = await firestore.collection("settings").where(firebase_instance.firestore.FieldPath.documentId(), 'in', roomData.participants).get();
        
        const hasJuniorColleague = othersRef.docs.filter(o => o.data['stage'] && (o.data['stage'] != "faculty") && o.data()['colleagues'].includes(me.id) ).length;
        if(hasJuniorColleague)
            return true;

        const seniorCnt = othersRef.docs.filter(o => o.data['stage'] && (o.data['stage'] == "faculty")).length;

        return seniorCnt < 2;
    }         

    return true; 
}

// Example usage
// console.log(findName('Jane')); // should return 'Jane Doe'
// console.log(findName('Smith')); // should return 'Mary Smith'
// console.log(findName('Mary S.')); // should return 'Mary Smith'
// console.log(findName('J. Doe')); // should return 'Jane Doe'
// console.log(findName('Mario')); // should return null
const findMatchingName = (search, users) => {
    search = search.trim().replaceAll(".", "");
    // Check if the search string matches any full names
    const fullNameMatches = users.filter(user => user.name.toLowerCase() === search.toLowerCase());
    if (fullNameMatches.length > 0) {
      return fullNameMatches[0];
    }

    // Check if the search string matches any first names
    const firstNameMatches = users.filter(user => user.name.toLowerCase().startsWith(`${search.toLowerCase()}`));
    if (firstNameMatches.length > 0) {
      return firstNameMatches[0];
    }
  
    // Check if the search string matches any last names
    const lastNameMatches = users.filter(user => user.name.toLowerCase().endsWith(`${search.toLowerCase()}`));
    if (lastNameMatches.length > 0) {
      return lastNameMatches[0];
    }
  
    // Check if the search string matches any first and last name combinations
    const firstAndLastNameMatches = users.filter(user => {
      const nameParts = user.name.split(' ');
      const firstName = nameParts[0].toLowerCase();
      const lastName = nameParts[nameParts.length - 1].toLowerCase();
      return `${firstName} ${lastName}` === search.toLowerCase();
    });
    if (firstAndLastNameMatches.length > 0) {
      return firstAndLastNameMatches[0];
    }
  
    // Check if the search string matches any name variants
    const variants = [];
    users.forEach(user => {
      const nameParts = user.name.split(' ');
      const firstName = nameParts[0];
      const lastName = nameParts[nameParts.length - 1];
      for (let i = 1; i < nameParts.length - 1; i++) {
        const middleInitial = nameParts[i][0];
        const variant = `${firstName} ${middleInitial} ${lastName}`;
        variants.push(variant);
      }
    });
    const variantMatches = variants.filter(variant => variant.toLowerCase() === search.toLowerCase());
    if (variantMatches.length > 0) {
      return variantMatches[0];
    }

    const searchParts = search.split(' ');
    const [searchFirstInitial, searchLastInitial] = [searchParts[0][0].toLowerCase(), searchParts[searchParts.length -1][0].toLowerCase()];
  
    // Check if the search string matches any initials
    const initialsMatches = users.filter(user => {
      const nameParts = user.name.split(' ');
      const firstNameInitial = nameParts[0][0].toLowerCase();
      const lastNameInitial = nameParts[nameParts.length - 1][0].toLowerCase();

      
      return `${firstNameInitial} ${lastNameInitial}` === `${searchFirstInitial} ${searchLastInitial}`;
    });
    if (initialsMatches.length > 0) {
      return initialsMatches[0];
    }
  
    // If no matches were found, return null
    return null;
}

  
export default isMyColleague;
export {conf_ID, compareDocuments, getBaconDistance, getColleaguesSS, getExpertise, getInterest, getSimiliarUsers, getLikedContent, getKeywords, findMatchingName, isJoinable};